<template>
	<div>
		<p>
			Você pode convidar novos membros para
			<span class="name">{{ merchantName }}</span>
		</p>
		<a-card title="Convidar membros" size="small" class="card">
			<a-form
				v-if="newMemberStatus === 'adding'"
				:form="form"
				@submit.prevent="sendInvitations"
			>
				<a-row>
					<a-form-item
						label="Endereços de email"
						style="margin-bottom: 0;"
					>
						<a-select
							:value="guests"
							mode="tags"
							style="width: 100%;"
							:token-separators="[',', ' ', ';', '|', '/']"
							placeholder="Adicione o email de um ou mais membros para convidá-los"
							@change="handleChangeEmails"
						/>
					</a-form-item>
				</a-row>
				<a-row :gutter="24">
					<a-col :span="18">
						<a-form-item label="Escolha uma permissão de acesso">
							<a-select
								v-model="invitationType"
								style="width: 100%;"
							>
								<a-select-option
									v-for="role in roles"
									:key="role.type"
								>
									{{ role.name }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="5">
						<a-form-item label="Acesso pré-ativado">
							<a-switch
								:checked="guestAccessActivated"
								size="small"
								@change="
									(value) => (guestAccessActivated = value)
								"
							/>
						</a-form-item>
					</a-col>
				</a-row>
				<a-row type="flex" justify="center">
					<a-form-item style="margin-bottom: 0;">
						<a-col>
							<a-button
								type="primary"
								style="width: 180px;"
								html-type="submit"
								:loading="loading"
								icon="mail"
								:disabled="guests.length === 0"
							>
								Enviar convites
							</a-button>
						</a-col>
					</a-form-item>
				</a-row>
			</a-form>
			<a-row
				v-else-if="newMemberStatus === 'sending'"
				type="flex"
				justify="center"
				style="flex-direction: column; margin: 0 32px;"
				:gutter="[16, 16]"
				align="middle"
			>
				<a-col>
					<a-progress
						type="circle"
						:stroke-color="{
							'0%': '#ff7e5f',
							'100%': '#feb47b',
						}"
						:percent="(currSending / guests.length) * 100"
						:width="80"
					/>
				</a-col>
				<a-col>{{
					`Enviando ${currSending} de ${guests.length} convites`
				}}</a-col>
			</a-row>
			<a-result
				v-else-if="newMemberStatus === 'success'"
				status="success"
				title="Todos os convites foram enviados com sucesso"
			>
				<template #extra>
					<a-button
						type="primary"
						icon="arrow-left"
						@click="
							() => {
								newMemberStatus = 'adding'
								guests = []
							}
						"
					>
						Voltar
					</a-button>
				</template>
			</a-result>
			<a-result
				v-else-if="newMemberStatus === 'failed'"
				status="error"
				title="Não possível enviar alguns convites"
				sub-title="Por favor cheque as seguintes informações e tente novamente"
			>
				<template #extra>
					<a-button
						type="primary"
						icon="arrow-left"
						@click="
							() => {
								newMemberStatus = 'adding'
								guests = []
								erros = []
							}
						"
					>
						Voltar
					</a-button>
				</template>

				<div class="desc">
					<p style="font-size: 15px;">
						<strong
							>Os convites enviados retornaram as seguintes
							mensagens de erro:</strong
						>
					</p>
					<p v-for="erro in erros" :key="erro.email">
						<a-icon :style="{ color: 'red' }" type="close-circle" />
						{{ `${erro.email}: ${erro.message}` }}
					</p>
				</div>
			</a-result>
		</a-card>

		<p
			style="
				font-weight: 500;
				color: rgba(0, 0, 0, 0.7);
				margin-bottom: 8px;
			"
		>
			Membros adicionados
		</p>
		<a-card size="small" class="card">
			<div slot="title" style="font-weight: normal;">
				Membros de <span class="name">{{ merchantName }}</span>
				<a-badge
					:count="membersCount"
					:number-style="{
						backgroundColor: '#e8e8e8',
						color: '#000',
					}"
					style="margin: 0px 8px 5px;"
					:show-zero="true"
					:overflow-count="999"
				/>
			</div>
			<div slot="extra" class="extra">
				<a-input
					v-model="filterString"
					style="width: 70%;"
					placeholder="Filtrar por nome... "
					@change="handleChangeFilterString"
				>
					<a-icon slot="prefix" type="search" />
				</a-input>
				<a-select
					style="width: 60%; margin-left: 8px;"
					:value="sortMode"
					@change="handleSortChange"
				>
					<a-select-opt-group>
						<span
							slot="label"
							style="font-size: 11px; color: rgba(0, 0, 0, 0.75);"
						>
							ORDENAR POR
						</span>
						<a-select-option
							v-for="option in sortOptions"
							:key="option.value"
						>
							{{ option.text }}
						</a-select-option>
					</a-select-opt-group>
				</a-select>
			</div>
			<a-list
				v-if="loading"
				item-layout="horizontal"
				:data-source="[1, 2, 3, 4, 5]"
			>
				<a-list-item
					slot="renderItem"
					key="item.title"
					slot-scope="item"
				>
					<a-skeleton
						:loading="true"
						:title="false"
						:paragraph="{ rows: 2, width: ['100%', '65%'] }"
						active
						avatar
					>
						{{ item }}
					</a-skeleton>
				</a-list-item>
			</a-list>
			<a-list
				v-else
				item-layout="horizontal"
				:data-source="filteredMembers"
			>
				<a-list-item
					slot="renderItem"
					slot-scope="item, index"
					style="padding: 8px 0;"
				>
					<ActionsButtons
						v-if="!blockEdit(item)"
						slot="actions"
						:is-available="item.is_active"
						:edit="false"
						:duplicate="false"
						description="acesso"
						style="color: rgba(0, 0, 0, 0.7);"
						@switch="(value) => handleSwitch(index, value)"
						@delete="handleDelete(item.id)"
					/>
					<a-list-item-meta
						v-if="item"
						:description="`Tem acesso desde ${getDate(
							new Date(item.date_joined)
						)}`"
					>
						<div slot="title">
							<span>
								{{ item.name }}
							</span>
							<span style="font-weight: lighter;">{{
								` - ${item.email}`
							}}</span>
							<a-tag
								v-if="user.email === item.email"
								color="#87d068"
								style="margin-left: 8px;"
							>
								Você
							</a-tag>
						</div>
						<a-avatar
							slot="avatar"
							:src="item.profile_picture"
							icon="user"
							:size="40"
						/>
					</a-list-item-meta>
					<a-dropdown-button
						size="small"
						:trigger="['click']"
						:disabled="blockEdit(item)"
					>
						{{ getType(item.type) }}
						<a-menu slot="overlay" type="primary">
							<a-menu-item
								v-for="role in roles.filter(
									(role) => role.type !== item.type
								)"
								:key="role.type"
								:disabled="blockChangeType(role.type)"
								@click="
									(event) =>
										handleChangeRole(event.key, index)
								"
							>
								{{ role.name }}
							</a-menu-item>
						</a-menu>
						<a-icon slot="icon" type="edit" />
					</a-dropdown-button>
				</a-list-item>
			</a-list>
		</a-card>
	</div>
</template>

<script>
import { ActionsButtons } from '@/modules/partner/components'
import { mapActions, mapGetters } from 'vuex'
import { validateEmail } from '@/utils/utils'

const roles = [
	{
		name: 'Proprietário',
		type: 'OWNER',
	},
	{
		name: 'Gerente',
		type: 'MANAGER',
	},
	{
		name: 'Garçom',
		type: 'WAITER',
	},
]

const sortOptions = [
	{ value: 'name', text: 'nome' },
	{ value: 'oldest', text: 'mais antigos' },
	{ value: 'newest', text: 'mais novos' },
]

export default {
	name: '',
	components: {
		ActionsButtons,
	},
	data() {
		return {
			loading: false,
			form: this.$form.createForm(this),
			roles,
			members: [],
			sortMode: 'name',
			sortOptions,
			filtereds: [],
			filterString: '',
			guests: [],
			guestAccessActivated: true,
			invitationType: 'WAITER',
			newMemberStatus: 'adding',
			currSending: 1,
			erros: [],
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
			merchantName: 'merchants/merchantName',
			user: 'account/user',
			accessType: 'merchants/accessType',
		}),
		membersCount: function () {
			return this.filteredMembers.length
		},
		filteredMembers: {
			set: function (filterString) {
				if (filterString !== '') {
					let elementsList = []
					let index, value

					for (index = 0; index < this.members.length; ++index) {
						value = this.members[index].name
						if (
							value &&
							typeof value === 'string' &&
							value
								.toLowerCase()
								.indexOf(filterString.toLowerCase()) >= 0
						) {
							elementsList.push(this.members[index])
						}
					}

					this.filtereds = [...elementsList]
				} else {
					this.filtereds = [...this.members]
				}

				this.handleSortChange(this.sortMode)
			},
			get: function () {
				return this.filtereds
			},
		},
	},
	watch: {
		members: function () {
			this.filteredMembers = this.filterString
		},
	},
	async beforeMount() {
		await this.getData()
	},
	methods: {
		...mapActions('merchants', [
			'getAccess',
			'editAccess',
			'deleteAccess',
			'inviteGuest',
		]),
		async getData() {
			try {
				this.loading = true
				const res = await this.getAccess(this.selectedMerchantId)

				this.members = res.map((access) => {
					const partner = access.partner
					delete access.partner

					return { ...access, ...partner }
				})
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		async sendInvitations() {
			this.newMemberStatus = 'sending'
			let invitation = {
				merchantId: this.selectedMerchantId,
				data: {
					type: this.invitationType,
					is_active: this.guestAccessActivated,
				},
			}

			let count = 0
			for (const guest of this.guests) {
				try {
					this.currSending = ++count
					invitation.data.email = guest

					await this.inviteGuest(invitation)
				} catch (err) {
					this.erros.push({
						email: this.guests[count - 1],
						message: err.message,
					})
				}
			}

			this.newMemberStatus = this.erros.length > 0 ? 'failed' : 'success'
			await this.getData()
		},
		handleChangeEmails(values) {
			this.guests = values.filter((value) => validateEmail(value))
		},
		handleSwitch(index, value) {
			this.members[index].is_active = value

			const payload = {
				merchantId: this.selectedMerchantId,
				accessId: this.members[index].id,
				data: { is_active: value },
			}
			this.editAccess(payload)
		},
		async handleDelete(id) {
			try {
				this.loading = true
				await this.deleteAccess({
					merchantId: this.selectedMerchantId,
					accessId: id,
				})

				this.members = this.members.filter((member) => member.id !== id)
			} catch (error) {
				//
			} finally {
				this.loading = false
			}
		},
		getType(type) {
			return roles.find((role) => role.type === type).name || 'Erro'
		},
		handleSortChange(value) {
			switch (value) {
				case 'name':
					this.sortProductsBy('name')
					break
				case 'oldest':
					this.sortProductsBy('date_joined')
					break
				case 'newest':
					this.sortProductsBy('date_joined', 1)
					break
				default:
					this.sortProductsBy('name')
					break
			}

			this.sortMode = value
		},
		sortProductsBy(property, order = 0) {
			let func = null

			if (order === 0) {
				func = (a, b) =>
					a[property] > b[property]
						? 1
						: b[property] > a[property]
						? -1
						: 0
			} else {
				func = (a, b) =>
					a[property] < b[property]
						? 1
						: b[property] < a[property]
						? -1
						: 0
			}

			this.filteredMembers.sort(func)
		},
		handleChangeFilterString(e) {
			this.filteredMembers = e.target.value
		},
		blockEdit(member) {
			const { email, type } = member

			return (
				this.user.email === email ||
				(type === 'OWNER' && this.accessType === 'MANAGER')
			)
		},
		blockChangeType(type) {
			return type === 'OWNER' && this.accessType === 'MANAGER'
		},
		getDate(date) {
			const options = { year: 'numeric', month: 'short', day: 'numeric' }

			return new Date(date).toLocaleString('pt-BR', options)
		},
		async handleChangeRole(key, index) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					accessId: this.members[index].id,
					data: { type: key },
				}
				const res = await this.editAccess(payload)
				this.members[index].type = res.type
			} catch (error) {
				//
			}
		},
	},
}
</script>

<style lang="less" scoped>
.name {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.85);
}

.card {
	border-radius: 5px;
	margin-bottom: 24px;

	.extra {
		display: flex;
		width: 350px;
	}
}

.desc {
	margin-top: 24px;
	padding: 24px 40px;
	background-color: #fafafa;
}
</style>
